/**
 * Created by osirvent on 08/04/2016.
 * Modified by aguerrero on 14/04/2016.
 */
angular
    .module('annexaApp')
    .controller('TerritoryController',['$rootScope', '$scope', '$state', 'HeaderService', 'Language', 'ErrorFactory', 'RouteStateFactory', 'TableFilterFactory',
        function ($rootScope, $scope, $state, HeaderService, Language, ErrorFactory, RouteStateFactory, TableFilterFactory) {
        $scope.languageColumn = Language.getActiveColumn();
        var vm = this;

    }])
    .controller('TerritoryListController',['$rootScope', '$scope', '$state', '$filter', '$stateParams', 'HeaderService', 'RouteStateFactory', 'TableFilterFactory', 'RestService', 'TerritoryModals', 'AnnexaFormlyFactory', 'TerritoryFactory', 'DialogsFactory', 'GlobalDataFactory', 'annexaFormFactory', 'AnnexaPermissionsFactory', 'HelperService',
        function ($rootScope, $scope, $state, $filter, $stateParams, HeaderService, RouteStateFactory, TableFilterFactory, RestService, TerritoryModals, AnnexaFormlyFactory, TerritoryFactory, DialogsFactory, GlobalDataFactory, annexaFormFactory, AnnexaPermissionsFactory, HelperService) {
    	$scope.$on('annexaAdminBaseReloadTable', function(event, args) {
            $scope.tableDefinition.reloadInternalData(true, true);
        });

    	$scope.tfilter = [
    		{ id: 'address', type: 'text', order: 0, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('territory','tableListOfTerritory','address'), negated:HelperService.getPrefilterNegated('territory','tableListOfTerritory','address') },
    		{ id: 'addressType', type: 'select', order: 1, label: 'global.literals.addressType', dataType: 'LOCAL', data: GlobalDataFactory.addressTypes, addAll: true, nameProperty: $scope.languageColumn, preFilter:HelperService.getPrefilter('territory','tableListOfTerritory','addressType', GlobalDataFactory.addressTypes, 'id'), negated:HelperService.getPrefilterNegated('territory','tableListOfTerritory','addressType') },
        	{ id: 'active', type: 'select', order: 2, label: 'global.territory.list.active', callAux: true, dataType: 'LOCAL', data: TerritoryFactory.activeOptions, addAll: false, nameProperty: "description", preFilter:HelperService.getPrefilter('territory','tableListOfTerritory','active', TerritoryFactory.activeOptions, 'id'), negated:HelperService.getPrefilterNegated('territory','tableListOfTerritory','active') }
        ];

    	var routeState = RouteStateFactory.getRouteState($state.current);
        if(routeState) {
            $scope.tfilter = routeState.state;
        }
        $scope.getFilterCall = getFilterCall; 
        $scope.getFilterCallAux = getFilterCallAux;
        $scope.submit = submit;

        function submit(val) {
            $scope.$broadcast('filterData', {});
        };

        HeaderService.onChangeState($scope, onChangeState);
        HeaderService.changeState($state.current);
        
        $scope.columnsAux = [
        	{id: 'address'+$scope.languageColumn, title: $filter('translate')('global.territory.list.address')},
        	{ id: 'addressType', column: new DatabaseTranslatedParentColumn($filter, 'global.literals.addressType', $scope.languageColumn) },
        	{ id: 'active', width: '10%', column: new BooleanColumn($filter, 'global.territory.list.active')}
        ];

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        $scope.columnsListOfQuery = angular.copy($scope.columnsAux);
        $scope.columnsListOfQuery.push({id: 'id', width: '8%', column: new ActionsColumn($filter('translate')('global.literals.actions'), 
        		new ActionButton('global.literals.see', 'seeElement([data])', 'fa-eye'),
                [ 
        			new ActionButton('global.territory.list.activeMessage','activeElement([data], true)', 'fa-trash', undefined, function(data, type, full, meta){
        				if(full && full.active){
        					return false;
        				}else{
        					return true;
        				}
        			}),
        			new ActionButton('global.territory.list.inactiveMessage','activeElement([data], false)', 'fa-trash', undefined, function(data, type, full, meta){
        				if(full && full.active){
        					return true;
        				}else{
        					return false;
        				}
        			})
        		]),sortable: false 
        });
        $scope.columnsListOfQuery = getDatatableColumnsSettings(datatableSettings, 'datatable_execute_territory', $scope.columnsListOfQuery);

        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_execute_territory', $scope.columnsListOfQuery);
        $scope.tableOrderProperties = {sortName: 'id', sort: [[0, 'desc']]};
        if (orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > 0) {
            $scope.tableOrderProperties = {
                sortName: orderProperties.name,
                sort: [[orderProperties.index, orderProperties.direction]]
            };
        }

        $scope.tableDefinition = {
            id: 'tableListOfTerritory',
            origin: 'territory',
            objectType: 'Address',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: $scope.getFilterCall(),
            filterCallAux: $scope.getFilterCallAux(),
            filterCallFunc: $scope.getFilterCall,
            filterCallAuxFunc: $scope.getFilterCallAux,
            columns: angular.copy($scope.columnsListOfQuery),
            containerScope: $scope,
			notAddExcel: true
        }


        function getFilterCall() {
            var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
            return filterCall;
        };


        function getFilterCallAux() {
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.langColumn = $scope.languageColumn;
            if(filterCallAux.active){
            	filterCallAux.active = filterCallAux.active.id;
            }
            return filterCallAux;
        };

        function onChangeState(message) {
            $rootScope.subHeadButtons = [
            	new HeadButtonSearch('#tableFilter')
            ];
        	var canCreate = TerritoryFactory.canCreateAddress();
            if(canCreate) $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-r-sm', undefined, '#dataTableExecuteTerritory', 'fa fa-plus', 'global.territory.list.new', undefined, 'newElement'));
			$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#dataTableExecuteTerritory', 'fa fa-download', 'global.literals.export', undefined, 'exportList'));
            $rootScope.subHeadTabs = [];
        };
        
        $scope.activeElement = function(id, active) {
        	var title = "global.territory.list.activeMessage";
        	var message = "global.territory.list.activeMessageConfirm";
        	var error = "global.territory.list.errorActive";
        	var url = "./api/territory/active/"+id;
        	if(!active){
        		title = "global.territory.list.inactiveMessage";
            	message = "global.territory.list.inactiveMessageConfirm";
            	error="global.territory.list.errorInactive";
            	url = "./api/territory/inactive/"+id;
        	}
        	DialogsFactory.confirm(title, message)
            .then(function (data) {
            	TerritoryFactory.changeState(url).then(function (data) {
	        		$scope.tableDefinition.reloadInternalData(true, true);
	            }).catch(function (error) {
	            	DialogsFactory.error($filter('translate')(error));
	            });
           }).catch(function (data) {
	               //Empty
	       });
        };
        
        $scope.seeElement = function(id) {
        	TerritoryFactory.viewAddress(id, true);
        };
        
        $scope.newElement = function() {
        	TerritoryFactory.newAddress(undefined, undefined, true);
        }

		$scope.exportList = function(){
			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
            .then(function (data) {
				RestService.exportData($scope.tableDefinition);
           }).catch(function (data) {
	               //Empty
	       });
		}

    }])
    .controller('TerritoryViewController',['$rootScope', '$scope', '$state', 'HeaderService', 'TerritoryFactory',
    function ($rootScope, $scope, $state, HeaderService, TerritoryFactory) {
    	$scope.address = TerritoryFactory.address;

    	HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.territory.view' && TerritoryFactory.address) {
                $rootScope.subHeadButtons = [];
            }
        });

        HeaderService.changeState($state.current, true);
    	
    }]);